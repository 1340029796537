import React from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import './ProductDescription.css';



const ProductDescription = ({ props }) => {
    const location = useLocation();
    let paragraphContent;

    switch (location.pathname) {
        case '/':
            paragraphContent = (
                <>
                    <p>
                        <b>
                        Savings Seeker is a monthly pet care service that 
                        provides veterinary telemedicine services to answer
                        questions and ease concerns.
                        </b>
                    </p>
                    <br />
                    <p>
                    The plan provides virtual veterinary care 24/7/365, saves you
                    money on veterinary visits, and gives you amazing discounts 
                    on everything related to your pet.
                    </p>
                    <br />
                    <p>
                    Get the fastest access to veterinary care for your pet in <b>just 4 
                    simple steps:  
                    </b>
                    </p>
                    <br />
                    <ol>
                        <li>All you need to do is to go to your health portal on the 
                        web and enter your <b>member ID and password.</b>
                        </li>
                        <li>
                        Fill out your member profile and add any pets you have.
                        </li>
                        <li>
                        Select the vet you want to start the video call with.
                        </li>
                        <li>
                        Start saving with <b>discounts of up to 70%</b>
                        </li>
                    </ol>
                </>
            ); 
            break;

        case '/about-us':
            paragraphContent = (
                <>
                    <p>
                        <b>
                        Savings Seeker is in partnership with Golexi, a virtual healthcare 
                        provider for pets.
                        </b>
                    </p>
                    <br />
                    <p>
                    To access your membership, please log in or go to
                    www.golexic.com and enter your Member ID. Make your pet's 
                    healthcare experience smarter and more fun.
                    </p>
                    <br />
                    
                    <p>
                        <b>Download our app, available for Apple or Android.</b> &nbsp;Connect
                        with a veterinarian and get quick and easy answers to all your
                        pet health questions and advice using our mobile application.
                    </p>
                   
                </>
            );
            break;

        default:
            paragraphContent = null;

    }

    return (



        <section className="ProductDescription__main-container" >
            <div className="ProductDescription__inner-container">
                <div className="ProductDescription__explanation-main-container">
                    <div className="ProductDescription__title-container">
                        <h1>{props.title}</h1>
                    </div>
                    <div className="ProductDescription__explanation-container">
                        {paragraphContent}
                    </div>
                </div>
                <div className="ProductDescription__img-container">
                    <div className="ProductDescription__img-inner-container">
                        <img src={props.img} alt={props.title} className="ProductDescription__img" />
                    </div>
                </div>
                
            </div>
        </section>



    );
};

export default ProductDescription;