import React, { useState } from "react";

import './Faq.css';

import Accordion from './components/Accordion/Accordion';
import ContactCard from '../../../shared/components/ContactCard/ContactCard';
import SectionInfo from "../../../shared/components/SectionInfo/SectionInfo";


const Faq = props => {

    const faqsInformation =
        [
            {
                question: "How do I log in to my account?",
                answer:
                    "A lot.",
                open: false
            },
            {


                question:
                    "How do I schedule a veterinary appointment?",
                answer: "This many!",
                open: false
            },
            {


                question:
                    "Veterinarians can write prescriptions?",
                answer: "This many!",
                open: false
            },
            {


                question:
                    "What is Golexi?",
                answer: "This many!",
                open: false
            },
            {


                question:
                    "What is not part of our service?",
                answer: "This many!",
                open: false
            },
            {


                question:
                    "How do I cancel?",
                answer: "This many!",
                open: false
            },
            
            





        ]

    const [faqs, setFaqs] = useState(faqsInformation)

    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };


    const borderStyle = "0em 0em 1.25em 1.25em";

    return (
        <>
            {/* <section className="Faq__main-container">
                <div className="Faq__main-title">
                <SectionInfo title={"FAQ"}color={"#f25c05"} borderStyle ={borderStyle } />
                </div> 
               
                <div className="Faq__faqs-container">
                    <div className="Faq__faqs columns faqs">
                        {faqs.map((faq, index) => (
                            <Accordion faq={faq} index={index} key={index} toggleFAQ={toggleFAQ}></Accordion>
                        ))}
                    </div>
                </div>
            </section> */}
            <section>
                <div className="Faq__customer-service-container">
                    <ContactCard />
                </div>
            </section>
        </>

    );
};

export default Faq;