import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


import PriceCard from "../PriceCard/PriceCard";
import './BannerSection.css';

const BannerSection = ({ props }) => {
    const subscriptionInformation = {
        subscriptionType: "MONTH",
        subscriptionPrice: "36.72",
        subscriptionTrialInfo: "Trial for 30 days at",
        subscriptionTrialPrice : "3.29",
        subscriptionDescription: "Monthly",
        subscriptionButtonText: "Buy"
    }
    return (
        <>
            {
                (props.whatBannerSection === "PRICE_BANNER_SECTION") ? (

                    <section className="BannerSection__main-section-container" style={{ backgroundImage: `url(${props.lengendBackground})` }}>
                        <div className="BannerSection__price-card-component-container">
                            <PriceCard props={subscriptionInformation}></PriceCard>
                        </div>
                    </section>
                ) : (
                    <section className="BannerSection__main-section-container" style={{ backgroundImage: `url(${props.lengendBackground})` }}>
                        <CardInfo props={props}></CardInfo>
                    </section>
                )
            }

        </>

    );
};




const CardInfo = ({ props }) => {
    let cardBodyText;
    const phoneNumber = "8003588099";
    const history = useHistory();
    const goToHandle = () => {

        switch( window.location.pathname){


            

            case '/terms-of-service':
                window.location.href = `tel:${phoneNumber}`;
                break;


            case '/privacy-policy':
            case '/':
            case '/about-us':
            case '/price':
                history.push('/contact');
                break;



       


            default:
                break;
        }

      
    }


    switch (window.location.pathname) {
        case '/':
            if (props.cardBannerPosition === 1) {
                cardBodyText = (
                    <p>
                        Avoid unnecessary veterinary visits and save money
                        with incredible discounts on pet medicines.
                        <span className="CardInfo__important-text"> &nbsp;Just one video call away! </span>
                    </p>
                )
            }
            else if (props.cardBannerPosition === 2) {
                cardBodyText = (
                    <p>
                        <span className="CardInfo__important-text">With a simple video call</span> &nbsp;and unlimited access to a
                        network of board-certified veterinarians available
                        24/7, you can get your questions answered
                        instantly.
                    </p>
                )
            }
            else {
                cardBodyText = (
                    <p>
                        Save time and money with our exclusive telehealth
                        plans. Get care whenever and wherever you need it, &nbsp;
                        <span className="CardInfo__important-text">just a phone call away!</span>
                    </p>
                )
            }
            break;

        case '/price':

            cardBodyText = (
                <p>
                   Get a second opinion,<span className="CardInfo__important-text"> resolve pet health concerns,
                   help with emergencies,</span> behavioral issues, pet health advice and training tips.
                </p>
            )
 
            break;

        case '/contact':
            cardBodyText = (
                <p>
                    <span className="CardInfo__important-text">Make a difference in your pet's life </span>with our advice
                    on how to train, educate and care for your pet.
                </p> 
            )
            break;

        case '/cancel-service':
            cardBodyText = (
                <p>
                    <span className="CardInfo__important-text">Canceling your service will be quick and easy.</span> The
                    reason you choose to cancel helps us improve our 
                    service. 
                </p>
            )

            break;

        case '/terms-of-service':
            cardBodyText = (
                <p>
                Please contact us if you have any questions or
                would like more information. <span className="CardInfo__important-text">We will be happy to 
                assist you.
                </span> 
            </p>
            )
            break;

        case '/privacy-policy':
            cardBodyText = (
                < p>
                   <span className="CardInfo__important-text">At Savings Seeker, your privacy is an important
                   concern to us.</span> Please feel free to contact us if you
                   have any questions or concerns.
                </p>
            )
            break;
        case '/refund-policy':
            cardBodyText = (
                <p>
                    <span className="CardInfo__important-text">We would be grateful for any feedback on what
                    went wrong or how we can do things better.</span> To
                    submit your refund request, please follow these
                    steps 
                </p>
            )
            break;
        case '/about-us':
            cardBodyText = (
                <p>
                    Learn about the benefits and join our community of
                    members using our services to <span className="CardInfo__important-text">improve their pets'
                    quality of life.</span> 
                    
                </p>
            )
            break;
        case '/checkout':
            cardBodyText = (
                <p>
                    Resolve your doubts, get incredible advice, exclusive
                    discounts, and <span className="CardInfo__important-text">24/7 access to a board-certified
                    veterinarian who is just a video call away.</span> 
                </p> 
            )
            break;
        default:
            cardBodyText = ( 
                <p>
                    It is possible that what you are trying to find has 
                    been moved or is no longer included.&nbsp;
                    <span className="CardInfo__important-text">Useful links are listed below.</span> 
                </p> 
            )
            break;
    }


    return (

        <div className="BannerSection__main-card-info-container">

            <div className="BannerSection__main-title-container">
                <h2 className="text-left">{props.cardTitle}</h2>
            </div>
            <div className="BannerSection__main-body-container">
                {cardBodyText}
            </div>
            {
                props.cardButtonText.trim() !== "" ? (
                    <div className="BanneSection__main-btn-container">
                        <div className="BannerSection__main-button-container">
                            <div className="BannerSection__inner-button-container">
                                <a href="" onClick={(e) => { e.preventDefault(); goToHandle() }}>
                                    {props.cardButtonText}
                                </a>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="BannerSection__button-container" style={{ minWidth: '10em' }}>
                    </div>
                )
            }
        </div>

    )
}


export default BannerSection;