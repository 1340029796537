import React from "react";
import './SubscriptionPlan.css';

import PriceCard from "../PriceCard/PriceCard";
import ContactCard from "../ContactCard/ContactCard";
import SubscriptionDescription from '../../../shared/components/SubscriptionDescription/SubscriptionDescription';







const SubscriptionPlan = ({props}) => {

    const [subscriptionCardInformation, subscriptionInformation] = props

    return (
        <>
        <section className="SubscriptionPlan__main-section-container" id="price">
            <div className="SubscriptionPlan__subscription-plans-container">
                <div className="SubscriptionPlan__subscription-description-component-container">
                     <SubscriptionDescription props={subscriptionInformation} /> 
                   
                </div>
                <div className="SubscriptionPlan__subscription-card-component-container">
                    <PriceCard props={subscriptionCardInformation} />
                </div>
            </div>
        </section>
        <section className="SubscriptionPlan__card-contact-component-container">
            <div className="SubscriptionPlan__contact-card">
                <ContactCard/>
            </div>
        </section>
        </>
    );
};

export default SubscriptionPlan;