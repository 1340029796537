import React from 'react';

import './OurTeam.css';

import TeamCard from './components/TeamCard/TeamCard';
import person1Img from '../../../utils/assets/aboutUs/person1.png';
import person2Img from '../../../utils/assets/aboutUs/person2.png';
import person3Img from '../../../utils/assets/aboutUs/person3.png';
import person4Img from '../../../utils/assets/aboutUs/person4.png';




const OurTeam = ({ props }) => {
   const theTeamInfo = [
      {
         personImg: person1Img,
         personName: 'Dr. Laurel C., DVM',
         personJob: 'My goal is the highest quality of care for pets.'
      },
      {
         personImg: person2Img,
         personName: 'Symphony. R, DVM',
         personJob: 'I love giving every client professional advice, every time.'
      },
      {
         personImg: person3Img,
         personName: 'Sharyl R., DVM',
         personJob: 'I want you to feel secure about your furry friend.'
      },
      {
         personImg: person4Img,
         personName: 'Anna M., DVM',
         personJob: 'I have been a veterinarian for over 10 years.  '
      }
   ]
   return (
      <section className="OurTeam__main-container" >
         <div className='OurTeam__inner-container'>
            <div className='OurTeam__container'>
               {
                  theTeamInfo.map((card, index) => (
                     <TeamCard key={index} props={card} />
                  ))
               }
            </div>
         </div>
      </section>
   )

}
export default OurTeam;