import React from "react";


import BannerSection from "../../shared/components/BannerSection/BannerSection";
import notFoundImg from '../../utils/assets/notFound/notFoundImg.png';
import Error404 from "../components/Error404/Error404";
const NotFound = () =>{

    const bannerSectionInfo =
    {
       cardTitle: "This is not right!",
       cardButtonText: "",
       lengendBackground: notFoundImg
    }

    return (
        <React.Fragment>
            <BannerSection props={bannerSectionInfo}/>
            <Error404/>

        </React.Fragment>
    );
}

export default NotFound;