import React, { useEffect } from "react";

import BannerSection from '../../shared/components/BannerSection/BannerSection';
import NavegationInfo from '../../shared/components/NavegationInfo/NavegationInfo';
import ProductDescription from '../../shared/components/ProductDescription/ProductDescription';
import SectionInfo from "../../shared/components/SectionInfo/SectionInfo";
import Faq from '../components/Faq/Faq';
import OurTeam from "../components/OurTeam/OurTeam";


import aboutUsBannerImg from '../../utils/assets/aboutUs/sleeping-kitten-hugging-a-doll.png';
import huggingCat from '../../utils/assets/aboutUs/woman-hugging-a-cat.png';




const AboutUs = props => {
    const bannerSectionInfo =
     {
        cardTitle: "Give them the unconditional love they deserve",
        cardButtonText: "Join us",
        lengendBackground: aboutUsBannerImg
     }

     const productDescription = {
        title : "Find out more about us",
        img : huggingCat
     }

    const borderStyle = " 1.5em 1.9em 0em 0em";

    useEffect(()=>{
      sessionStorage.clear();
      return ()=>{
         sessionStorage.clear();
     }
    })
           
    return(                    
        <React.Fragment>
           <BannerSection props={bannerSectionInfo}/>
            <NavegationInfo page="About us"/>
            <ProductDescription props={productDescription} />
            <SectionInfo title={'Meet some of the team behind it'} borderStyle={borderStyle} color={'#64078c'}/>
            <OurTeam/>
           <Faq/>
        </React.Fragment>
    );
};

export default AboutUs;