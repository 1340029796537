import React from "react";

import './PrivacyTerms.css'
import ContactCard from '../../../shared/components/ContactCard/ContactCard';

const PrivacyTerms = props => {

    return (
        <>
            <section className="PrivacyTerms__main-section-container">
                <div>
                    <h1 className="text-center PrivacyTerms__main-title">Privacy policy</h1>
                </div>
                <div className="PrivacyTerms__description">
                    <p>
                        This privacy policy is designed to provide information regarding the collection, use and disclosure of personal information gathered when you visit
                        Savings Seeker llc website. This privacy policy currently is applicable only to United States customers and visitors.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        1. Our privacy policy
                    </p>
                    <p></p>
                    <p>
                        We will describe in plain English what personal information we collect, how we protect it, and what we may do with it. And we will honor our business
                        relationships with other companies that restrict the sharing of personal information with others. We will give you additional disclosures or obtain your
                        authorization when required by applicable law.
                    </p>
                    <p></p>
                    <p>
                        We may obtain information to provide you products and services you have requested, to service your membership and as we deem appropriate, to
                        determine your eligibility for products or services. We may collect identification, contact, transaction and experience information from applications, and
                        from transactions with us. Examples of the sources and types of information we may collect include: applications, registration forms, or other forms
                        containing your name, address, telephone number and email address.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        2. Disclosures to service providers
                    </p>
                    <p></p>
                    <p>
                        We may disclose contact information that we collect, to companies that provide services on our behalf.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        3. Disclosures permitted by law
                    </p>
                    <p></p>
                    <p>
                        We may also disclose information required by law. For example, we may disclose information to law enforcement agencies or state regulatory authorities.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        4. Gramm Leach Bliley Act
                    </p>
                    <p></p>
                    <p>
                        We comply fully with the Gramm Leach Bliley Act which was designed to protect the confidentiality of customers’ nonpublic personal information and to
                        communicate applicable provisions of such privacy program with customers.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        5. Change in privacy policy
                    </p>
                    <p>

                        We reserve the right to modify or supplement this privacy policy at any time. We will always post our current privacy policy on our website.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        6. Our security procedures
                    </p>
                    <p></p>
                    <p>
                        We comply fully with the Gramm Leach Bliley Act which was designed to protect the confidentiality of customers’ nonpublic personal information and to
                        communicate applicable provisions of such privacy program with customers.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        7. Online information and collection
                    </p>
                    <p></p>
                    <p>
                        You can visit us on the web without revealing any additional information about yourself, including your email address. In this case, our web servers only
                        collect the name of the domain you used to access the Internet, such as “aol.com” or “msn.com,” and the website you came from.
                    </p>
                    <p></p>
                    <p>
                        We also monitor site performance to make the site easier and more convenient to use. We may aggregate statistics that we gather about our customers,
                        sales, traffic patterns, and services, and provide these statistics to third parties. When we provide these statistics to third parties, however, they will not
                        include any personal information that identifies you.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        8. Cookies
                    </p>
                    <p></p>
                    <p>

                        Cookies are small pieces of data stored by your Internet browser on your computer’s hard drive. We, along with thousands of other websites, use cookies
                        to enhance your web viewing experience and to measure your website usage. With the help of cookies, we may present you with customized offerings or
                        content that may be of interest to you. We may also use cookies to recognize you on subsequent visits or to remember your user name so you do not 
                        have to re-enter it each time you visit our site. You may be able to set your browser to notify you when you receive a cookie or to prevent cookies from 
                        being sent. Please note however, that by not accepting cookies, you may limit the functionality we can provide to you when you visit our site.

                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        9. Links to other websites
                    </p>
                    <p></p>
                    <p>
                    We are not responsible for the information collection practices of other websites when you link to them from our website. We cannot guarantee how 
                    those parties use cookies or whether they place on your computer cookies that may identify you personally. You should carefully review the privacy 
                    policies of each website you visit to understand how they collect, use and disclose information.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        10. Information from children
                    </p>
                    <p></p>
                    <p>
                    We do not knowingly solicit information from children and we do not intentionally market our products or services to children.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        11. Email communications
                    </p>
                    <p></p>
                    <p>
                    Occasionally, we may send you email communications with information that may be useful to you, including information about our products and services
                    or about offerings or third parties. You will be given the option of not receiving any such email messages, if you choose.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        12. Encryption
                    </p>
                    <p></p>
                    <p>
                    We use encryption and authentication tools to protect information we gather on our website. Email communications outside of our website, however, 
                    may not be protected. Instead of sending us an email communication which includes highly confidential information, such as a credit card number, you 
                    may want to call us or send it by regular mail.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        13. Access
                    </p>
                    <p></p>
                    <p>
                    In some cases, you may be able to update certain information that you provided to us. This may require you to use a unique user name and password. 
                    Also, where the capability exists, you may request that we modify or delete information we have gathered during your visit to our website.
                    </p>
                    <p className="text-decoration-underline">
                        14. Visitors/registered users
                    </p>
                    <p></p>
                    <p>
                    As a visitor to our website, you may have access to certain features without revealing any additional information about yourself. We will not gather any   
                    information about you when you are using these features. We may require you to become a registered member in order to gain access to and use other 
                    features. Once you become a registered member, we may collect personal information when you use our website.
                    </p>
                    <p></p>
                    <p>
                    This privacy policy will not apply to your relationships with companies that are not part of this program. Their privacy policies will govern how they 
                    collect, use and disclose personal information that you allow them to access. This privacy policy applies only to the information collection practices of this
                    program. This notice may be required by law in some areas. In order to exercise certain rights provided by law, you may need to take further action.
                    </p>

                </div>
            </section>
            <section className="PrivacyTerms__customer-service-container">
                <div className="PrivacyTerms__customer-service">
                    <ContactCard />
                </div>
            </section>

        </>
    );
};

export default PrivacyTerms;