import React from "react";

import BenefitCard from "./components/BenefitCard/BenefitCard";

import vetCareIcon from '../../../utils/assets/home/icons/vet-care.png';
import medicineForPetIcon from '../../../utils/assets/home/icons/medicine-for-pets.png';
import guidanceSupportIcon from '../../../utils/assets/home/icons/guidance-and-support.png';
import clearUpIcon from '../../../utils/assets/home/icons/clear-up-any-doubts.png';
import emergencyRecognitionIcon from '../../../utils/assets/home/icons/emergency-recognition.png';
import improveIcon from '../../../utils/assets/home/icons/improve-pets.png';




import './Benefit.css';
const Benefit = ({ props }) => {

    const benefitCardInformation = [
        {
            cardTitle: "Vet Care 24/7",
            cardIcon: vetCareIcon,
            cardDescription: "Connect to a veterinarian by video or phone 24/7 with our pet telemedicine service."
        },
        {
            cardTitle: "Medicines for pets",
            cardIcon: medicineForPetIcon,
            cardDescription: "Save up to 75% on pet medications at over 68,000 pharmacies across the United States."
        },
        {
            cardTitle: "Guidance and Support",
            cardIcon: guidanceSupportIcon,
            cardDescription: "Monitor your pet's health by following up on treatments and routine checkups."
        },
        {
            cardTitle: "Clear up any doubts",
            cardIcon: clearUpIcon,
            cardDescription: "Know when to take your pet to the emergency room, ask questions, and get a second opinion."
        },
        {
            cardTitle: "Emergency recognition",
            cardIcon: emergencyRecognitionIcon,
            cardDescription: "Determine whether to take your pet to the emergency room or provide treatment at home."
        },
        {
            cardTitle: "Improve pet's well-being",
            cardIcon: improveIcon,
            cardDescription: "Learn about training, behavior issues, and more from the comfort of your own home."
        }
    ]


    return (
        <section className="Benefit__main-contiainer">
            <div className="Benefit__inner-contianer">
                {
                    benefitCardInformation.map((cardInfo, index) => (
                        <BenefitCard key={index} props={cardInfo} />
                    ))
                }
            </div>
        </section>
    );
};

export default Benefit; 