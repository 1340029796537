import React from "react";

import './SubscriptionDescription.css';

const  SubscriptionDescription = ({ props }) => {
    let contentToRender ;
    switch( window.location.pathname ){
        case '/price':
            contentToRender = (
                <p>
                    Save thousands of dollars on discounted pet medications and 
                    checkups, and stop spending money on non-emergency 
                    veterinary visits <b>We believe pets' health and happiness are most important.</b> 
                </p>
            )
            break; 
        case '/':
            contentToRender = (
                <p>
                      Protect your identity and personal information from theft with 
                      the protection and security you need.
                      more, all in the blink of an eye. <b>Know that we have
                      your back, saving you money, time and peace of mind.
                      </b>
                </p>
            );
            break;

        default:
            contentToRender = null;
            break;
    }

    return (
        <div className="SubscriptionDescription__main-container" >
        
              <div className="SubscriptionDescription__img-container mb-4" >
                    <div className="SubscriptionDescription__inner-img-container">
                        <img src={props.img} alt={props.imgDescription} className="SubscriptionDescription__img" />
                    </div>
            </div> 
            <div className="SubscriptionDescription__title-container mt-4">
                <p>
                    {props.title}
                </p>
            </div>
            <div className="SubscriptionDescription__description-container mt-4">
               
                {contentToRender}
                
            </div>
        </div>
    );
};

export default SubscriptionDescription;