import React, {useEffect} from "react";

import rabbitBannerImg from '../../utils/assets/contact/rabbit-in-customer-service.png';
import BannerSection from "../../shared/components/BannerSection/BannerSection";
import NavegationInfo from "../../shared/components/NavegationInfo/NavegationInfo";
import ContactUs from '../components/ContactUs/ContactUs';




const Contact = props => {

    useEffect(()=>{
        window.scroll(0,0);
    })

    const bannerSectionInfo =
    {
       cardTitle: "No matter what the situation, we are here to help",
       cardButtonText: "",
       lengendBackground: rabbitBannerImg
    }

    return (
        <React.Fragment>
            <BannerSection props={bannerSectionInfo}/>
            <NavegationInfo page="Contact"/>
            <ContactUs />

        </React.Fragment>
    );
};

export default Contact;