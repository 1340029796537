import React from 'react';

import './TeamCard.css';



const TeamCard =( {props} )=>{

    return(
        <div className="TeamCard__main-container">
        <div className="TeamCard__inner-container">
            <div className="TeamCard__header-container">
                <div className="TeamCard__media-container">
                    <div className="TeamCard__icon-container">
                        <img src={props.personImg} alt="person icon" className="TeamCard__icon" />
                    </div>
                </div>
            </div>
            <div className="TeamCard__divider"></div>
            <div className="TeamCard__about-container">
            <div className="TeamCard__description-container">
                <div >
                    <p>
                        {props.personName}
                    </p>
                </div>
            </div>
            <div className="TeamCard__about-information">
                <div className="TeamCard__information">
                    <p>
                        {props.personJob}
                    </p>
                </div>
            </div>
            </div>
        </div>
    </div>
    )

}
export default TeamCard;