import React, { useEffect } from "react";

import BannerSection from "../../shared/components/BannerSection/BannerSection";
import ProductDescription from '../../shared/components/ProductDescription/ProductDescription';
import Benefit from "../components/Benefit/Benefit";
import SectionInfo from "../../shared/components/SectionInfo/SectionInfo";
import BenefitSection from "../components/BenefitSection/BenefitSection";
import ContactCard from '../../shared/components/ContactCard/ContactCard';

import huggingCatBannerImg from '../../utils/assets/home/couple-hugging-a-cat.png';
import happyAndHealtyPetsImg from '../../utils/assets/home/happy-and-healthy-pets.png';
import reachVetImg from '../../utils/assets/home/reach-veterinarian.png';
import vetTelehealthImg from '../../utils/assets/home/vet-telehealth-for-pets.png';

import assitenceIcon from '../../utils/assets/home/icons/assistence-icon.png';
import educationIcon from '../../utils/assets/home/icons/education-icon.png'
import trainingIcon  from '../../utils/assets/home/icons/training-icon.png';
import medicationsIcon from '../../utils/assets/home/icons/medications-icon.png';
import boardCertifiedIcon from '../../utils/assets/home/icons/boards-icon.png';
import petWellnessIcon from '../../utils/assets/home/icons/pet-wellness-icon.png';



const Home = props => {
    const bannerSectionInfo =
    [
        {
            cardTitle: "Make your pets wellbeing a priority",
            cardBannerPosition : 1,
            cardButtonText: "Join Us",
            lengendBackground: huggingCatBannerImg
        },
        {
            cardTitle: "Reach a veterinarian whenever needed",
            cardBannerPosition : 2,
            cardButtonText: "Start Now",
            lengendBackground: reachVetImg
        },
        {
            whatBannerSection : "PRICE_BANNER_SECTION",
            lengendBackground: happyAndHealtyPetsImg
        }

    ]

    const productDescription = {
        title: "THIS IS HOW OUR SERVICES WORK",
        img: vetTelehealthImg
    }

    const subscriptionInformation = [                     
        {       
            subscriptionType: "BASIC",
            subscriptionPrice: "29.95",
            subscriptionTrialInfo: "Trial for 21 days at",
            subscriptionTrialPrice : "3.49",
            subscriptionDescription: "Monthly subscription",
            subscriptionButtonText: "Buy"
        }, 
        {       
            subscriptionType: "PREMIUM",
            subscriptionPrice: "39.95",
            subscriptionTrialInfo: "Trial for 21 days at",
            subscriptionTrialPrice : "3.49",
            subscriptionDescription: "Monthly subscription",
            subscriptionButtonText: "Buy"
        }                          
     
    ]


    const benefitsInfo = {
        sectionName : "BENEFITS",
        icon1 : assitenceIcon,
        description1 : "24-7 ASSISTANCE",
        icon2: educationIcon,
        description2 : "CANINE EDUCATION",
        icon3 : trainingIcon,
        description3 : "PET TRAINING TIPS",
        icon4 : medicationsIcon,
        description4 : "DISCOUNTED PET MEDICATIONS",
        icon5 : boardCertifiedIcon,
        description5:"BOARD-CERTIFIED VETS IN THE U.S.",
        icon6 : petWellnessIcon,
        description6 : "PET WELLNESS"

    }


    useEffect(()=>{
        window.scroll(0,0);
        sessionStorage.clear();
    },[])
 
    const firstSectionBorderStyle = '0em 0em 1.25em 1.25em';
    const secondSectionBorderStyle = '1.25em 1.25em 0em 0em ';

    
    return (
        <React.Fragment>
            <BannerSection props={bannerSectionInfo[0]}/>
            <ProductDescription props={productDescription}/>
            <BannerSection props={bannerSectionInfo[1]}/>
            <SectionInfo title={"Get to know our benefits"} color={"#64078c"} borderStyle ={firstSectionBorderStyle}/> 
            <Benefit/>
            <SectionInfo title={"Start now with the trial for ONLY $3.29"} color={"#64078c"} borderStyle ={secondSectionBorderStyle }/> 
            <BannerSection props={bannerSectionInfo[2]}/>
            <BenefitSection props={benefitsInfo} />
            <section className="solitary-section">
                <ContactCard />
            </section>
        </React.Fragment>
    );
};

export default Home;