import React, { useState } from 'react';
import { Link } from 'react-router-dom';



import './Header.css';

import SideDrawer from './components/SideDrawer/SideDrawer';
import Backdrop from '../UiElements/Backdrop/Backdrop';
import NavegationLinks from './components/NavegationLinks/NavegationLinks';
import Header from './components/Header/Header';

//assets
import savingsSeekerLogo from '../../../utils/assets/logo/savings-seeker-logo.png';
import facebookIcon from '../../../utils/assets/icons/facebook-icon.png';
import instagramIcon from '../../../utils/assets/icons/instagram-icon.png';

const MainNavigation = props => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  
  const openDrawerHandler = () => {
    setDrawerIsOpen(true);
  };

  const closeDrawerHandler = () => {
    setDrawerIsOpen(false);
  };

  const handleGoTo = () =>{
    window.scroll(0,0);
  }
  return (
    <React.Fragment>
      {drawerIsOpen && <Backdrop onClick={closeDrawerHandler} />}
      <SideDrawer show={drawerIsOpen} onClick={closeDrawerHandler}>
        <nav className="main-navigation__drawer-nav">
          <NavegationLinks />
        </nav>
      </SideDrawer>

      <Header>
        <button
          className="main-navigation__menu-btn"
          onClick={openDrawerHandler}
        >
          <span />
          <span />
          <span />
        </button>
        <h1 className="main-navigation__title">
          <img src={savingsSeekerLogo} alt='savingsSeekerLogo' className='Navigation__logo' onClick={handleGoTo}></img>
        </h1>
        <nav className="main-navigation__header-nav">
          <NavegationLinks />
        </nav>
        <div className='MainNavigation__main-social-media-container'>
          <div className='MainNavigation__inner-social-media-container'>
            <a href='https://www.instagram.com/savings.seeker.llc/' target='_blank'><img src={instagramIcon} alt='facebook-icon' style={{marginRight : '.3em'}}></img></a>
            <a href='https://www.facebook.com/savingsseekerllc' target='_blank'><img src={facebookIcon} alt='instagram-icon' style={{marginRight : '.3em'}}></img></a>
          </div>
        </div>


      </Header>
    </React.Fragment>
  );
};

export default MainNavigation;
