import React from "react";
import './BenefitSection.css'


const BenefitSection = ({props}) => {

    return(
        <section className="BenefitSection__section-container">
            <div className="BenefitSection__inner-container">
                <div className="BenefitSection__icons-container">
                    <div className="BenefitSection__inner-icons-container">
                        <div className="BenefitSection__icon-container">
                            <div>
                                <img src={props.icon1} alt="doctor-icon" className="BenefitSection__icon" />
                            </div>
                        </div>
                        <div className="BenefitSection__text-container">
                            <p>{props.description1}</p>
                        </div>
                    </div>

                    <div className="BenefitSection__inner-icons-container">
                     
                        <div className="BenefitSection__icon-container">
                            <div>
                                <img src={props.icon2} alt="doctor-icon" className="BenefitSection__icon" />
                            </div>
                        </div>
                        <div className="BenefitSection__text-container">
                            <p>{props.description2}</p>
                        </div>
                    </div>

                    <div className="BenefitSection__inner-icons-container">
                   
                        <div className="BenefitSection__icon-container">
                            <div>
                                <img src={props.icon3} alt="doctor-icon" className="BenefitSection__icon"/>
                            </div>
                        </div>
                        <div className="BenefitSection__text-container">
                          <p>{props.description3}</p>
                        </div>
                    </div>

                    <div className="BenefitSection__inner-icons-container">
                    
                        <div className="BenefitSection__icon-container">
                            <div>
                                <img src={props.icon4} alt="doctor-icon" className="BenefitSection__icon"/>
                            </div>
                        </div>
                        <div className="BenefitSection__text-container">
                          <p>{props.description4}</p>
                        </div>
                    </div>

                    <div className="BenefitSection__inner-icons-container">
                        <div className="BenefitSection__icon-container">
                            <div>
                                <img src={props.icon5} alt="doctor-icon" className="BenefitSection__icon"/>
                            </div>
                        </div>
                        <div className="BenefitSection__text-container">
                          <p>{props.description5}</p>
                        </div>
                    </div>

                    <div className="BenefitSection__inner-icons-container">
                        <div className="BenefitSection__icon-container">
                            <div>
                                <img src={props.icon6} alt="doctor-icon" className="BenefitSection__icon"/>
                            </div>
                        </div>
                        <div className="BenefitSection__text-container">
                          <p>{props.description6}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BenefitSection;