import React from "react";
import './MembershipTerms.css'

import ContactCard from '../../../shared/components/ContactCard/ContactCard';

const MembershipTerms = props => {


    return (
        <>
            <section className="Membership__main-section-container">
                <div>
                    <h1 className="text-center Membership__main-title"> Terms of membership</h1>
                </div>
                <div className="Membership__section-description">
                    <p>
                        The following is the Membership Agreement between Savings Seeker llc (“We and Us”) and the person who has enrolled in the Savings Seeker llc
                        program (“You”). <b>. UPON ENROLLMENT, YOU AGREE TO BE BOUND BY THESE TERMS OF MEMBERSHIP. YOU SHOULD READ THIS MEMBERSHIP
                            AGREEMENT CAREFULLY </b>  If you have any questions regarding your Membership, You may contact our customer service center 24 hours a day at the
                        toll-free number listed on your Membership Card.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        1. Program Offer
                    </p>
                    <p></p>
                    <p>
                        By placing your order you are enrolling in the Savings Seeker LLC program and you will be entitled to access all the money saving benefits and
                        Televeterinary services available to program members 24 hours a day for the period of 30 days from the date of your sign up. You will be charged a non-
                        refundable Introductory Processing Fee of $3.29 which covers your introductory membership period. At the end of the introductory period, your monthly
                        membership fee will be automatically billed to your designated billing source. If you decide to cancel your membership within the trial period, simply call
                        the toll-free number listed in your membership information and welcome letter, and you will only be responsible for the initial processing fee.
                    </p>
                    <p className="text-decoration-underline">
                        2. Membership Benefits
                    </p>
                    <p></p>
                    <p>
                        As a Member, You are entitled to access discounts and/or other Benefits on various products and services offered by participating vendors through the
                        Savings Seeker llc Website and the customer service center (“Benefits”), as described in your Membership Guide or on the program website (“Program
                        Website”). Some Benefits may not be available in your area. Additionally, the discounts available through the program may not be used in conjunction
                        with any other discount program.
                    </p>
                    <p></p>
                    <p>
                        <b>PLEASE SEE YOUR MEMBERSHIP MATERIALS AND THE PROGRAM WEBSITE FOR IMPORTANT DETAILS AND LIMITATIONS.</b>All listed or quoted
                        prices are current prices only and are subject to change without notice. We reserve the right to suspend or end the program or certain aspects thereof,
                        without prior notice, in our sole discretion. We also reserve the right to suspend or end certain aspects of the program in certain geographic areas,
                        without prior notice, in our sole discretion.

                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        3. Membership Terms
                    </p>
                    <p></p>
                    <p>
                        Your Membership is effective immediately upon your enrollment in the program and shall continue on a month-to-month basis until cancelled as
                        described below. You may cancel your Membership at any time by calling Us at the toll-free number listed on your membership card or by writing Us at
                        the address indicated below. Unless You notify Us that You wish to cancel your membership by following these instructions or your membership is
                        otherwise cancelled as provided herein, your Membership will continue automatically and You will be billed the then-current membership fee which will
                        appear on your statement, depending upon how You enrolled.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        4. Payment of Membership Fee
                    </p>
                    <p></p>
                    <p>
                        The payment of your membership fee (which, if applicable, includes any trial period fee as well as enrollment or processing fees and shipping and
                        handling charges) is made automatically by a charge to the payment source authorized by You (the “Payment Source”) in accordance with the payment
                        terms to which You agreed. In the event that the program offer to which you agreed includes a trial period, You may cancel your membership at any time
                        during the trial period and not be charged, other than any one time enrollment or processing fees, and shipping and handling charges, in each case
                        depending on the program offer to which you agreed, as well as money paid for program Benefits ordered by You. We reserve the right to terminate your
                        Membership at any time without notification, including in the event that We are unable to bill the membership fee to your specified billing source.


                    </p>
                    <p className="text-decoration-underline">
                        5. Promotional Membership Fee
                    </p>
                    <p></p>
                    <p>
                        From time to time, in our sole discretion, We may provide a reduced membership fee, for a promotional period, to first-time or other selected customers.
                        If You are provided with such a promotional reduction in your membership fee (“Promotional Membership Fee”), your Payment Source will be charged the
                        Promotional Membership Fee for the promotional period. You agree that any such reduced price is only valid for the promotional period established by
                        Us, and that the regular applicable periodic membership fee will be charged to your Payment Source following the end of the promotional period. Terms
                        of the Promotional Membership Fee, including the duration and amount of the Promotional Membership Fee, may vary from time to time.

                    </p>
                    <p className="text-decoration-underline">
                        6. Continuation of Membership
                    </p>
                    <p></p>
                    <p>
                        Unless you notify Us that You wish to cancel your Membership by following the instructions in the paragraph below titled “Termination of Membership”,
                        your Membership will continue automatically and You will be billed the then-current membership fee which will appear on your Payment Source
                        statement, depending upon how You enrolled. We reserve the right to increase or decrease the membership fee, or add new fees and charges, from time
                        to time.
                    </p>
                    <p></p>
                    <p>
                        You agree that unless You cancel your membership prior to the effective date of the membership fee increase, You will be charged the new applicable
                        periodic membership fee on each anniversary date after the effective date of such change, and You authorize Us to charge the new applicable periodic
                        membership Fee to your Payment Source. You are solely responsible for any and all fees charged to your Payment Source by the issuer, bank, or financial
                        institution including, but not limited to, membership, processing, shipping and handling, overdraft, insufficient funds and over-the-credit-limit fees, in each
                        case to the extent applicable.

                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        7. Electronic Fund Transfer Authorization
                    </p>
                    <p></p>
                    <p>
                        If the Payment Source You have provided is a checking account, You authorize Us to create an electronic funds transfer request (“EFT”) which will be
                        presented to your bank for payment from your checking account. To extend your membership Term month-to-month, You authorize Us to charge your
                        checking account on periodic anniversary dates and therefore to effect pre-authorized transfers from your checking account. Your request to pay your
                        recurring monthly membership fee with pre-authorized charges to your checking account and your electronic execution of an acknowledgement of your
                        acceptance of this Membership Agreement (“Electronic Signature”) constitutes your pre-authorized EFT authorization for future charges on your checking
                        account and your consent to these terms and conditions.
                    </p>
                    <p></p>
                    <p>
                        You further acknowledge that the amount charged to your checking account may be different from time to time, in accordance with this Membership
                        Agreement, including, without limitation, differing amounts due to Promotional Membership Fees or changes in your membership plan, and You authorize
                        Us to charge your checking account for such varying amounts. You agree that if an EFT is returned unpaid, You will pay a service charge of the maximum
                        allowed by law. EFTs returned for insufficient or uncollected funds, together with service charges, may be debited electronically from your account or
                        collected using a bank draft drawn from your account. You may cancel your membership as described herein.
                    </p>
                    <p></p>
                    <p>
                        Such cancellation will cancel any checking account authorization that was to occur on any day after the date of cancellation. You may also cancel your
                        pre-authorized debit authorization by contacting your bank within a sufficient time to cancel the authorization (Please see your agreement with your bank
                        for the terms and conditions of cancellation). Please note, however, that You must still notify Us of the cancellation of your membership as described
                        herein, as cancellation of your authorization with your bank will not serve as notice to Us concerning cancellation of your membership.

                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        8. Use of Membership
                    </p>
                    <p></p>
                    <p>

                        Your Membership is non-assignable and non-transferable. You agree that only You and your immediate family members may use the Membership. The
                        term “Immediate Family” shall be defined as You, your spouse, and your children, to the extent each is living in your home with a legal address that is the
                        same as yours. Benefits are not to be resold. You are limited to one membership per twelve-month period per Immediate Family. You are responsible for
                        all use of your Membership and will promptly notify Us if You become aware of any unauthorized use of your Membership, your membership card or
                        membership number, or if your membership card is lost or stolen. If You were offered the opportunity to claim a premium or gift in connection with your
                        enrollment in the program, You are limited to one premium or gift per program and, depending upon the offer You agreed to, You may be required to be a
                        member of the program at the time that your claim is processed.

                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        9. Disclaimer of Liability
                    </p>
                    <p></p>
                    <p>
                        You agree that We and our subsidiaries, affiliates, partners and providers are not responsible or liable for any Benefits provided by participating vendors
                        and, if You have any claims relating to such Benefits, You will make your claim against the vendors providing the Benefits. <b>WE MAKE NO WARRANTIES,
                            EXPRESS OR IMPLIED, INCLUDING THE WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, WITH RESPECT TO
                            ANY OF THE BENEFITS OR RELATED INFORMATION PROVIDED TO YOU</b> We do not guarantee, nor are responsible for, the quality of products or
                        services provided by any independent vendors.
                    </p>
                    <p></p>
                    <p>

                        We reserve the right to eliminate, add, change and substitute Benefits and participating vendors without notice to You in our sole discretion. We assume
                        no responsibility for the payment of or contribution to any use or sales tax on the Benefits which may be imposed by taxing authorities, and such taxes,
                        to the extent imposed, shall remain your sole responsibility or that of the provider of the Benefits, as the case may be.

                    </p>
                    <p></p>
                    <p>
                        <b>
                        IN NO EVENT SHALL WE OR ANY OF OUR AFFILIATES, PARTNERS AND PROVIDERS OR ANY OF THEIR RESPECTIVE DIRECTORS, OFFICERS,
                        EMPLOYEES, AGENTS OR OTHER REPRESENTATIVES BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, 
                        PUNITIVE OR AGGRAVATED DAMAGES OR ANY OTHER DAMAGES OF ANY KIND, ARISING OUT OF OR IN CONNECTION WITH THE 
                        PROGRAM, PROGRAM WEBSITE, MEMBERSHIP GUIDE, ANY MATERIALS, INFORMATION, QUALIFICATION AND RECOMMENDATIONS 
                        APPEARING ON ANY PROGRAM WEBSITE, ANY SOFTWARE, TOOLS, TIPS, PRODUCTS, OR SERVICES OFFERED THROUGH, CONTAINED IN OR
                        ADVERTISED ON ANY PROGRAM WEBSITE, AND/OR ANY LINK PROVIDED ON ANY PROGRAM WEBSITE, WHETHER OR NOT WE HAVE BEEN 
                        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THIS EXCLUSION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY 
                        LAW. THIS PROVISION SHALL SURVIVE THE TERMINATION OF YOUR MEMBERSHIP.
                        </b>
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        10. Changes to Terms and Conditions
                    </p>
                    <p></p>
                    <p>
                    We may, at any time, and at our sole discretion, modify this Membership Agreement by posting the modified Membership Agreement on the Program 
                    Website. You agree to review this Membership Agreement periodically on the Program Website. If You do not agree to any modification of this 
                    Membership Agreement, You must immediately cancel your membership. Continuing your membership following any such modifications will constitute 
                    your acceptance of the modified Membership Agreement.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                       11. Termination of Membership
                    </p>
                    <p></p>
                    <p>
                    YOU MAY TERMINATE THIS MEMBERSHIP AGREEMENT AND YOUR MEMBERSHIP AT ANY TIME BY CALLING US AT THE TOLL-FREE NUMBER 
                    LISTED ON YOUR MEMBERSHIP CARD/MATERIALS OR BY NOTIFYING US IN WRITING AT CUSTOMER SERVICE, SAVINGS SEEKER LLC, 
                    5401 Bayou Grande Blvd NE Saint Petersburg, FL 33703.YOUR CANCELLATION WILL BE EFFECTIVE 
                    PROMPTLY UPON THE RECEIPT OF YOUR CANCELLATION REQUEST. UPON CANCELLATION, YOU WILL NOT OWE ANY FURTHER 
                    MEMBERSHIP FEES AND WILL NOT BE ENTITLED TO A REFUND OF PAST FEES CHARGED TO YOUR ACCOUNT. 

                    </p>
                    <p></p>
                    <p>
                    DEPENDING UPON THE TERMS YOU AGREED TO, ANY ENROLLMENT, PROCESSING AND/OR TRIAL PERIOD FEES MAY NOT BE REFUNDABLE.
                    ANY OTHER FEES OR CHARGES INCURRED IN CONNECTION WITH THE PROGRAM (INCLUDING BANK OR OVERDRAFT CHARGES) ARE YOUR 
                    RESPONSIBILITY. WE WILL TERMINATE YOUR MEMBERSHIP IF IT IS NOT USED IN ACCORDANCE WITH THIS MEMBERSHIP AGREEMENT OR
                    YOU HAVE NOT PROPERLY ENROLLED IN THE PROGRAM. 
                    </p>
                    <p></p>
                    <p>
                    IN SUCH A CASE, WE RESERVE THE RIGHT TO: (1) NOT REFUND MEMBERSHIP FEES (INCLUDING, WITHOUT LIMITATION, ANY ENROLLMENT
                        OR PROCESSING FEES, SHIPPING AND HANDLING CHARGES AND OTHER FEES, IN EACH CASE TO THE EXTENT APPLICABLE) PAID BY YOU 
                        AND/OR (2) NOT FULFILL ANY PENDING ORDERS FOR BENEFITS PURCHASED PRIOR TO CANCELLATION OF THE MEMBERSHIP. WE RESERVE
                        THE RIGHT TO TERMINATE YOUR MEMBERSHIP AT ANY TIME FOR ANY OTHER REASON. A MEMBER IS PROHIBITED FROM RE-ENROLLING IN
                        THE PROGRAM FOR TWELVE (12) MONTHS FOLLOWING CANCELLATION.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                       12. Entire Agreement
                    </p>
                    <p></p>
                    <p>
                    This Agreement contains all of the terms and conditions of Membership and no representations, inducements, promises or agreements concerning the 
                    Membership not included in this agreement shall be effective or enforceable. If any of the terms of this Agreement shall become invalid or unenforceable.
                    the remaining terms shall remain in full force and effect.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                       13. Governing Law                    
                    </p>
                    <p></p>

                    <p>
                        <b>THIS MEMBERSHIP AGREEMENT AND THE TERMS OF MEMBERSHIP SHALL BE GOVERNED AND CONSTRUED IN ACCORDANCE WITH THE 
                        LAWS OF THE STATE OF NEW YORK, WITHOUT GIVING EFFECT TO THE CHOICE OF LAW PROVISIONS THEREOF.
                        </b>
                    </p>
                    <p className="text-decoration-underline">
                       14. Arbitration                    
                    </p>
                    <p></p>
                    <p>
                    PLEASE READ THIS PROVISION CAREFULLY. IT PROVIDES THAT ANY DISPUTE MAY BE RESOLVED BY BINDING ARBITRATION. ARBITRATION 
                    REPLACES THE RIGHT TO GO TO COURT, INCLUDING THE RIGHT TO BRING OR PARTICIPATE IN A CLASS ACTION OR SIMILAR PROCEEDING.
                    IN ARBITRATION, A DISPUTE IS RESOLVED BY AN ARBITRATOR INSTEAD OF A JUDGE OR JURY. THE ARBITRATOR’S DECISION WILL 
                    GENERALLY BE FINAL AND BINDING. ARBITRATION PROCEDURES ARE SIMPLER AND MORE LIMITED THAN COURT PROCEDURES.
                    </p>
                    <p></p>
                    <p>
                    Any claim, dispute or controversy between You and Us (or made by or against anyone connected with You or Us, or claiming through You or Us) arising 
                    from or relating to your membership (“Claim”), including Claims regarding applicability or validity of this arbitration provision, shall be resolved by binding 
                    arbitration in accordance with the rules of the American Arbitration Association (“AAA”) (except for any AAA rules providing for class claims or class 
                        arbitration) then in effect, subject to this Membership Agreement.
                    </p>
                    <p></p>
                    <p>
                    Any Claim regarding the validity or enforceability of this arbitration provision shall be governed by the laws of the State of New York, without giving effect
                    to the choice of law provisions thereof. This arbitration provision is made pursuant to a transaction involving interstate commerce and, in all other 
                    respects, including the determination of any questions about whether Claims are within the scope of this arbitration provision and therefore subject to
                    arbitration shall be governed by the Federal Arbitration Act, 9 U.S.C. §1-16 (“FAA”), and shall be resolved by interpreting the arbitration provision in the 
                    broadest way the law will allow it to be construed.
                    </p>
                    <p></p>
                    <p>
                    All Claims are subject to arbitration, no matter what theory they are based on or what remedy they seek. This includes Claims based on contract, tort 
                    (including intentional tort), fraud, agency, negligence, statutory or regulatory provisions, or any other source of law. Claims made and remedies sought as 
                    part of a class action, private attorney general or other representative action are subject to arbitration on an individual (non-class, non-representative) 
                    basis. As an exception to arbitration, You and We retain the right to pursue in a small claims court located in the federal judicial district that includes your
                    billing address at the time of the Claim, any Claim that is within the court’s jurisdiction and proceeds on an individual basis.
                    </p>
                    <p></p>
                    <p>
                    The arbitration shall be conducted before a single arbitrator, applying to the Claims the substantive laws of the State of New York without giving effect to
                    the choice of law provisions thereof. The arbitrator’s authority is limited solely to the Claims between You and Us alone. The arbitration will not be 
                    consolidated with any other arbitration proceeding. You and We do not agree to any arbitration on a class action or representative basis, and the
                    arbitrator shall not be authorized to treat any Claim on a class action or representative basis.
                    </p>
                    <p></p>
                    <p>
                    If You prevail in the arbitration of any Claim against Us, We will reimburse You for any fees that You paid to the AAA in connection with the arbitration. 
                    Any decision rendered will be final and binding on the parties, and judgment may be entered in a court of competent jurisdiction. Arbitration rules and 
                    forms may be obtained from the AAA at http://www.adr.org/. Claims shall be filed in any AAA office. However, any participatory hearing that You attend
                    shall take place in New York, New York, unless You chose to have the hearing take place in the federal judicial district that includes your billing address at
                    the time the arbitration Claim is filed. This arbitration provision applies to all Claims now in existence or that may arise in the future. The arbitration
                    provision shall survive termination of your membership as well as voluntary payment of the debt in full by You or any bankruptcy by You.
                    </p>
                    <p></p>
                    <p>
                    IF YOU DO NOT CHOOSE TO ACCEPT THIS BINDING ARBITRATION PROVISION, YOU MUST NOTIFY US IN WRITING BY REGISTERED MAIL AT
                    ARBITRATION OPT-OUT, SAVINGS SEEKER LLC 5401 Bayou Grande Blvd NE Saint Petersburg, FL 33703. WITHIN TWENTY (20) DAYS AFTER 
                    RECEIPT OF THIS MEMBERSHIP AGREEMENT. IF YOU SO NOTIFY US BY THAT TIME THAT YOU DO NOT ACCEPT THE BINDING ARBITRATION 
                    PROVISION, YOU MAY CONTINUE TO BE A MEMBER FOR THE CURRENT MEMBERSHIP TERM UNLESS THE MEMBERSHIP IS OTHERWISE 
                    TERMINATED HEREUNDER. HOWEVER, IN THE EVENT YOUR MEMBERSHIP IS CONTINUED, WE SHALL HAVE THE RIGHT NOT TO RENEW
                    YOUR MEMBERSHIP AT THE END OF THE TWELFTH MONTH ANNIVERSARY DATE.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                      15. Availability Restrictions                    
                    </p>
                    <p></p>
                    <p>
                    This program is only available to residents of the contiguous United States (excluding any such states as We may designate from time to time in your 
                        Membership Guide and/or the Program Website). Orders to U.S. military post offices cannot be fulfilled.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                      16. Consent to Electronic Communications                   
                    </p>
                    <p></p>
                    <p>
                    You consent to receive communications from Us about your membership electronically, either by e-mail or by notices posted on the Program Website, as 
                    determined by Us in our sole discretion. You agree that any requirement that a notice, disclosure, agreement, or other communication be sent to You by
                    Us in writing is satisfied by such electronic communication. You agree that We may send You e-mails which include notices about your membership as 
                    well as information pertaining to the program and services, such as featured products/services or new offerings. You agree that this information is part of 
                    your membership with Us.
                    </p>

                </div>
            </section>

            <section className="MembershipTerms__customer-service-container">
                <div className="MembershipTerms__customer-service">
                    <ContactCard />
                </div>
            </section>
        </>
    );
};

export default MembershipTerms;