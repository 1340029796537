import React from "react";


import BannerSection from '../../shared/components/BannerSection/BannerSection';
import NavegationInfo from '../../shared/components/NavegationInfo/NavegationInfo';
import SectionInfo from '../../shared/components/SectionInfo/SectionInfo';

import dogWithGlassesImg from '../../utils/assets/price/happy-dog-with-glasses-in-the-yard.png';
import withPetsImg from '../../utils/assets/price/with-the-pets.png';
import SubscriptionPlan from "../../shared/components/SubscriptionPlan/SubscriptionPlan";


 

const Price = props => {
     const bannerSectionInfo =
     {
        cardTitle: "Give your 4-legged friend the best in veterinary care",
        cardButtonText: "Try Now",
        lengendBackground: dogWithGlassesImg
     }



    const borderStyle = '1.25em';
    const marginStyle = '0em 2em 2em 2em'

    const subscriptionInformation = [
        {       
            subscriptionType: "MONTH",
            subscriptionPrice: "36.72", 
            subscriptionTrialInfo: "Trial for 30 days at",
            subscriptionTrialPrice : "3.29",
            subscriptionDescription: "Monthly",
            subscriptionButtonText: "Buy"
        }, 
        {
            img: withPetsImg,
            title : "Connect with this incredible community and improve your pets' lives"
        }
    ]




    return ( 
        <React.Fragment>
             <BannerSection ection props={bannerSectionInfo}/>
             <NavegationInfo page="price"/>
             <SectionInfo title={"Get started now and try for ONLY $3.29"} color={"#64078c"} borderStyle ={borderStyle} marginStyle= {marginStyle}/> 
             <SubscriptionPlan props={subscriptionInformation}/>

        </React.Fragment>
    );            
};

export default Price;