import React from "react";

import './NavegationInfo.css';




const NavegationInfo = props => {


    return (
        <section className="NavegationInfo__section-container" >
            <div className="NavegationInfo__information">
                <span> Home {">>"} {props.page} </span>
            </div>
        </section>
    );
};

export default NavegationInfo;